import React, { createContext, useContext, useEffect, useState } from 'react'
import enTranslations from '../../app/language/en.json';
import deTranslations from '../../app/language/de.json';

const LanguageContext = createContext();

export const useTranslation = () => {
    return useContext(LanguageContext);
}

const LanguageProvider = ({ children }) => {

    const translations            = { en: enTranslations, de: deTranslations };
    const storedLanguage          = localStorage.getItem('selectedLanguage');
    const [language, setLanguage] = useState(storedLanguage || 'en');

    useEffect(() => {
    }, [language]);

    const changeLanguage = (newLanguage) => {
        setLanguage(newLanguage);
        localStorage.setItem('selectedLanguage', newLanguage);
    };
    return (
        <LanguageContext.Provider value={{ language, translations, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    )
}

export default LanguageProvider;