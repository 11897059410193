import React, { useEffect, useState } from "react";
import NavBar from "./partials/navbar";
import { Outlet } from "react-router-dom";
import '../../app/assets/admin/css/app.css';
import MainHeader from "./partials/mainHeader";
import Footer from "./partials/footer";

const UserLayout = ({ children }) => {
    const [isMainMenueOpen, setMainMenueOpen] = useState(false);

    const handleMainMenuStatus = (isMainMenuOpen) => {
        console.log(isMainMenuOpen)
        setMainMenueOpen(isMainMenuOpen);
    }

    return (
        <main className="main admin-layout userapp" id="top">
            <div className="container-fluid px-0" data-layout="container">
                <MainHeader handleMainMenueStatus={handleMainMenuStatus} />
                <NavBar />
                <div className="content">
                    <Outlet />
                    <Footer />
                </div>
            </div>
        </main>
    );
}

export default UserLayout;
