import React from "react";
import { Outlet } from "react-router-dom";
import '../../app/assets/public/css/app.css';
import LanguageProvider from "../../app/context/LanguageProvider";

const ProfileLayout = ({ children }) => {

    return (
        <LanguageProvider>
            <div className="admin-layout userapp">
                <Outlet />
            </div>
        </LanguageProvider>
    );
}

export default ProfileLayout;