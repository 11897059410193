import { useLocation, Navigate, Outlet } from 'react-router';
import cookie from 'react-cookies';
import axios from 'axios';
import { useNavigate } from 'react-router';

const RequireAuth = ({ allowedRoles }) => {
    const location = useLocation();
    const navigate = useNavigate();

    // Load roles from cookie
    const roles = cookie.load('roles');

    // Set Authorization header if authToken exists
    if (cookie.load('authToken')) {
        axios.defaults.headers.common['Authorization'] = cookie.load('authToken');
    }

    // Intercept axios response
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response.status === 401 || error.response.status === 403) {
            // Remove authToken and roles from cookies
            cookie.remove('authToken', { path: '/' });
            cookie.remove('roles', { path: '/' });
            navigate('/login');
            window.location.reload();
        }
    });

    // Check if user is authenticated and has required roles
    const isAuthenticated = cookie.load('authToken');
    const hasRequiredRoles = roles && allowedRoles.some(role => roles?.includes(role));

    return (
        // If user is authenticated and has required roles, render Outlet
        isAuthenticated && hasRequiredRoles
            ? <Outlet />
            // If user is not authenticated, redirect to login page
            : !isAuthenticated
                ? <Navigate to="/login" state={{ from: location }} replace />
                // If user is authenticated but doesn't have required roles, redirect to unauthorized page
                : <Navigate to="/unauthorized" state={{ from: location }} replace />
    );
}

export default RequireAuth;
