import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import axios from 'axios';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast, { Toaster } from 'react-hot-toast';

const ResetPassword = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [submited, setSubmited] = useState(false);

    const from = location.state?.from?.pathname || '/login';

    const getTokenFromUrl = () => {
        const params = new URLSearchParams(location.search);
        return params.get('token');
      };
    
      const token = getTokenFromUrl();

    const schema = yup.object({
        password       : yup.string().required('Password is mendatory').min(6, 'Password must be at 6 char long'),
        confirmPassword: yup.string().required('Password is mendatory').oneOf([yup.ref('password')], 'Passwords does not match'),
    });

    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const notify = (message) => toast.error(message,
        {
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        }
    );

    const notifySuccess = (message) => toast.success(message,
        {
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        }
    );

    const onSubmit = async (data) => {
        const requestBody = { ...data, token: token}
        setSubmited(true);

        try {
            const response = await axios.post('auth/reset-password', requestBody);
            if (response.status === 200) {
                setSubmited(false)
                notifySuccess(response.data.message);
                setTimeout(() => {
                    navigate(from, { replace: true });
                }, 1000);
            } else {
                notify(response.data.message); // Display error message returned from the server
            }
        } catch (err) {
            console.error("Error:", err);
            if (err.response) {
                notify(err.response.data.message)
            } else {
                notify('An error occurred. Please try again later.')
            }
        }
    }

    return (
        <>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <main class="main" id="top">
                <div class="container-fluid px-0" data-layout="container">
                    <div class="container">
                        <div class="row flex-center min-vh-100">
                            <div class="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-5">
                                <div className='mb-4'>
                                    {/* <img src='/logo.png' /> */}
                                    <h3>Secure Your Account</h3>
                                    <p>Reset Your Password Quickly and Easily</p>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div class="mb-3 text-start">
                                        <label class="mb-1" for="email">New password<span className='text-danger'>*</span></label>
                                        <div class="form-icon-container">
                                            <input
                                                type="password"
                                                placeholder="Enter your email"
                                                {...register('password')}
                                                className={
                                                    `form-control form-icon-input auth-input `
                                                    + `${errors.hasOwnProperty('password') ? 'is-invalid' : ''}`
                                                }
                                            />
                                            <span class="fas fa-user text-900 fs--1 form-icon input-icon"></span>
                                            <div className="invalid-feedback">
                                                {errors.password?.message}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3 text-start">
                                        <label class="mb-1" for="password">Confirm password <span className='text-danger'>*</span></label>
                                        <div class="form-icon-container">
                                            <input
                                                type="password"
                                                placeholder="Enter your password"
                                                {...register('confirmPassword')}
                                                className={
                                                    `form-control form-icon-input auth-input `
                                                    + `${errors.hasOwnProperty('confirmPassword') ? 'is-invalid' : ''}`
                                                }
                                            />
                                            <span class="fas fa-key text-900 fs--1 form-icon input-icon"></span>
                                            <div className="invalid-feedback">
                                                {errors.confirmPassword?.message}
                                            </div>
                                        </div>
                                    </div>
                                    <button class="btn btn-primary w-100 mb-3 mt-3 d-flex text-ceneter f-pass-btn">
                                        {submited ?
                                            <>
                                                <span className="spinner-border custom-spinner-border-sm" role="status" aria-hidden="true"></span>
                                                &#160;Reset Password
                                            </>
                                            :
                                            <>Reset Password</>
                                        }
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

function mapStateToProps(state) {
    const auth = state.authenticate.auth;

    return { auth };
}

export default connect(mapStateToProps)(ResetPassword);
