import { createSlice } from '@reduxjs/toolkit'

export const contactSlice = createSlice({
    name: 'contact',
    initialState: {
        contacts : [],
        contact  : {},
        result   : '',
    },
    reducers: {
        getContactsReducer: (state, action) => {
            state.contacts = action.payload;
        },
        getContactReducer: (state, action) => {
            state.contact  = action.payload.data;
        },
        addResultReducer: (state, action) => {
            state.result = action.payload.success
        },
    },
});

export const { getContactsReducer, getContactReducer, addResultReducer } = contactSlice.actions;
export default contactSlice.reducer;
