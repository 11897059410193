const Footer = ({ }) => (
    <footer className="footer position-absolute">
        <div className="row g-0 justify-content-between align-items-center h-100">
            <div className="col-12 col-sm-auto text-center">
                <p className="mb-0 mt-2 mt-sm-0 text-900">Copyright 2023 &copy;
                    <a className="mx-1" href="https://infocliq.net">Infocliq</a>
                    All rights reserved.
                </p>
            </div>
            <div className="col-12 col-sm-auto text-center">
                <p className="mb-0 text-600">v1.0.0</p>
            </div>
        </div>
    </footer>
);

export default Footer;
