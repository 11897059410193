import React from 'react';
import { Routes, Route } from "react-router-dom";

import PublicLayout from '../layout/public/main';
import UserLayout from '../layout/user/main';
import RequireAuth from './components/RequireAuth';

import Login from '../containers/auth/login';
import ForgotPassword from '../containers/auth/forgotPassword';
import Register from '../containers/auth/register';
import VerifyOtp from '../containers/auth/otpVerify';
import ResetPassword from '../containers/auth/resetPassword';
import MailVerification from '../containers/auth/emailVerify';

import MyCards from '../containers/user/dashaboard';
import Customize from '../containers/user/customize/customize';
import Contact from '../containers/user/contacts/contact';
import Service from '../containers/user/services/services';
import Product from '../containers/user/products/products';
import Setting from '../containers/user/settings/setting';

import ProfileLayout from '../layout/profile/main';
import ProfileCard from '../containers/profile/profile';
import NotFound from './components/NotFound';

export default function CustomRoutes() {
    return (
        <Routes>
            <Route path="/uid/:slug" element={<ProfileLayout />}>
                <Route index element={<ProfileCard />} />
            </Route>

            <Route>
                <Route path="/" element={<PublicLayout />}>
                    <Route path="login" element={<Login />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                    <Route path="signup" element={<Register />} />
                    <Route path="reset-password" element={<ResetPassword />} />
                    <Route path="email-verification" element={<MailVerification />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={['Admin', 'User']} />}>
                    <Route path="/" element={<UserLayout />}>
                        <Route index element={<MyCards />} />
                        <Route path='customize' element={<Customize />} />
                        <Route path="contacts" element={<Contact />} />
                        <Route path="services" element={<Service />} />
                        <Route path="products" element={<Product />} />
                        <Route path="settings" element={<Setting />} />
                    </Route>
                </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}