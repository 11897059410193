import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { 
    BagIcon,
    StackIcon,
    PieChartIcon,
    ArrowRightIcon,
    SettingIcon,
    UsersIcon,
    CubeIcon,
} from "../../../app/assets/admin/icons/icons";

const MainHeader = (props) => {
    const location = useLocation();

    const [isMenuOpen, setMenueOpen] = useState(false);
    const [menuItems, setMenuItems]  = useState([
        {
            title: 'My Cards',
            isOpen: getStoredState('mycards') || false,
            route: '/',
            icon: <PieChartIcon />,
        },
        {
            title: 'Customize',
            isOpen: getStoredState('customize') || false,
            route: '/customize',
            icon: <UsersIcon />,
        },
        {
            title: 'Contacts',
            isOpen: getStoredState('contacts') || false,
            route: '/contacts',
            icon: <StackIcon />,
        },
        {
            title: 'Services',
            isOpen: getStoredState('services') || false,
            route: '/services',
            icon: <CubeIcon />,
        },
        {
            title: 'Products',
            isOpen: getStoredState('products') || false,
            route: '/products',
            icon: <BagIcon />,
        },
        {
            title: 'Settings',
            isOpen: getStoredState('settings') || false,
            route: '/settings',
            icon: <SettingIcon />,
        },
    ]);
    

      function getStoredState(key) {
        const storedState = localStorage.getItem(key);
        return storedState === 'true';
      }
      
      function setStoredState(key, value) {
        // localStorage.setItem(key, value.toString());
      }

      const toggleSubMenu = (index) => {
        const updatedMenuItems = [...menuItems];
        updatedMenuItems[index].isOpen = !updatedMenuItems[index].isOpen;
        setMenuItems(updatedMenuItems);
        setStoredState(updatedMenuItems[index].title.toLowerCase(), updatedMenuItems[index].isOpen); //For menus open toggle
      };

      useEffect(() => {
        return () => {
          localStorage.clear();
        };
      }, []);

    const handleMenuToggle = () => {
        setMenueOpen(!isMenuOpen);
    }

    useEffect(() => {
        props.handleMainMenueStatus(isMenuOpen);
    }, [isMenuOpen])

    const handleActive = (path) => {
        return location.pathname === path ? 'active active-menu' : null;
    }

    return (
        <>
            <nav className="navbar navbar-vertical navbar-expand-lg">
                <div className={`navbar-collapse collapse ${isMenuOpen ? 'show' : null}`} id="navbarVerticalCollapse">
                    <div className="navbar-vertical-content">
                        <ul className="navbar-nav flex-column" id="navbarVerticalNav">
                            {menuItems.map((menuItem, index) => (
                                <div className="nav-item-wrapper" key={index} style={{marginBottom: 15}}>
                                    <a className={`nav-link dropdown-indicator label-1 collapsed ${handleActive(menuItem.route) }`}
                                        href={menuItem.route} role="button" data-bs-toggle="collapse"
                                        aria-expanded={menuItem.isOpen} aria-controls="email"
                                        onClick={() => toggleSubMenu(index)}>
                                        <div className="d-flex align-items-center">
                                            <div className="dropdown-indicator-icon">
                                            {!menuItem.submenus ? '' : <ArrowRightIcon />}
                                            </div>
                                            <span className="nav-link-icon">
                                                {menuItem.icon}
                                            </span>
                                            <span className="nav-link-text">{menuItem.title}</span>
                                        </div>
                                    </a>
                                    {menuItem.isOpen && menuItem.submenus && (
                                        <div className="parent-wrapper label-1">
                                            <ul className="nav parent collapse show" data-bs-parent="#navbarVerticalCollapse" id="email">
                                                {menuItem.submenus.map((submenu, subIndex) => (
                                                    <li className="nav-item" key={subIndex}>
                                                        <a className="nav-link" href={submenu.route} data-bs-toggle={submenu.route} aria-expanded="false">
                                                            <div className="d-flex align-items-center">
                                                                <div className="align-items-center d-flex">
                                                                    <span className="nav-link-icon">
                                                                        {submenu.icon}
                                                                    </span>
                                                                    <span className="nav-link-text">{submenu.title}</span>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="navbar-vertical-footer">
                    <button className="btn navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center">
                        <span className="uil uil-left-arrow-to-left fs-0"></span>
                        <span className="uil uil-arrow-from-right fs-0"></span>
                        <span className="navbar-vertical-footer-text ms-2">Developer Support</span>
                    </button>
                </div>
            </nav>
            <nav className="navbar navbar-top navbar-expand-lg" id="navbarTop">
                <div className="navbar-logo">
                    <button onClick={e => { e.preventDefault(); handleMenuToggle(); }} className="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTopCollapse" aria-controls="navbarTopCollapse" aria-expanded="false" aria-label="Toggle Navigation"><span className="navbar-toggle-icon"><span className="toggle-line"></span></span></button>
                    <a className="navbar-brand me-1 me-sm-3 ml-0" href="/">
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                                <img src="/images/logo/logo-solid-dark.svg" alt="senisha" style={{width: '140px'}} />
                            </div>
                        </div>
                    </a>
                </div>
            </nav>
        </>
    );
};

export default MainHeader;
