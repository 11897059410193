import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { BoxGridIcon, CartIcon } from '../../../app/assets/admin/icons/icons';

const NavBar = (props) => {
    const strokeWidthStyle = {
    };

    return (
        <nav className="navbar navbar-vertical">
            <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
                <div className="navbar-vertical-content">
                    <ul className="navbar-nav flex-column" id="navbarVerticalNav">
                        <li className="nav-item">
                            <div className="nav-item-wrapper">
                                <a className="nav-link dropdown-indicator label-1"
                                    href="/admin/product" role="button" data-bs-toggle="collapse"
                                    aria-expanded="true" aria-controls="home">
                                    <div className="align-items-center d-flex">
                                        <span class="nav-link-icon">
                                            <CartIcon />
                                        </span>
                                        <span className="nav-link-text">Products</span>
                                    </div>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
};

export default NavBar;
