import React, { useState, useRef } from 'react'
import { QRCodeSVG } from 'qrcode.react';
import toast, { Toaster } from 'react-hot-toast';
import html2canvas from 'html2canvas';
import { APP_URL } from '../../../app/globals';

export const ShareCard = ({ cardId }) => {
    const qrCodeRef = useRef(null);
    const [profileUrl, setProfileUrl] = useState(`${APP_URL}/uid/`);
    
    const qrGenUrl = `${APP_URL}/uid/${cardId}`

    const notify = () => toast('Link copied to clipboard!',
        {
            icon: '👏',
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        }
    );

    const handleLinkCopy = () => {
        navigator.clipboard.writeText(profileUrl + cardId)
            .then(() => {
                notify()
            })
            .catch((error) => {
                console.error('Failed to copy text: ', error);
            });
    };

    const handleDownloadQr = () => {
        const qrCodeElement = qrCodeRef.current;

        if (!qrCodeElement) {
            console.error('QR code element not found.');
            return;
        }

        // Delay the conversion to allow the QR code to render
        setTimeout(() => {
            // Convert the QR code to a PNG image using html2canvas
            html2canvas(qrCodeElement)
                .then((canvas) => {
                    // Create a data URL for the PNG image
                    const pngDataUrl = canvas.toDataURL('image/png');

                    // Create a download link for the PNG image
                    const a = document.createElement('a');
                    a.href = pngDataUrl;
                    a.download = 'qrcode.png';

                    // Trigger a click event to initiate the download
                    a.click();
                })
                .catch((error) => {
                    console.error('Error converting QR code to PNG:', error);
                });
        }, 100); // Adjust the delay time as needed
    };

    const qrCode = () => {
        return (
            <QRCodeSVG
                value={qrGenUrl}
                size={120}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"M"}
                includeMargin={false}
                // imageSettings={{
                //     src: "/img/icons/stop.png",
                //     x: undefined,
                //     y: undefined,
                //     height: 30,
                //     width: 30,
                //     excavate: true,
                // }}
            />
        )
    };

    return (
        <>
            {/* <Toaster
                position="bottom-right"
                reverseOrder={false}
            /> */}
            <div className='sprd-column p-3 userapp'>
                <div className='customize-form'>
                    <div className='qr-unit'>
                        <div className='row g-3'>
                            <div className='col-12 col-md-8'>
                                <h6 className='share-title'>Share card with a QR Code</h6>
                                <p className='sub-descrption mt-2'>
                                    This QR code can then be scanned by others to quickly
                                    access the information on the card, making the sharing
                                    process more convenient.
                                </p>
                                <p onClick={handleDownloadQr}>Download Qr</p>
                            </div>
                            <div className='col-12 col-md-4'>
                                <div className='qr-svg'>
                                    <div ref={qrCodeRef}>
                                        {qrCode()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='url-unit py-2'>
                        <h6 className='share-title'>Copy profile link</h6>
                        <div className='row g-3'>
                            <div className='profileurl'>
                                <div className='col-12 col-md-9'>
                                    <div className='linkpad mt-3'>
                                        <span className='user-link'>{profileUrl + cardId}</span>
                                    </div>
                                </div>
                                <div className='col-12 col-md-3'>
                                    <span className='copy-link' onClick={handleLinkCopy}>
                                        <i className='fal fa-copy'></i> Copy
                                    </span>
                                </div>
                            </div>
                        </div>
                        <a className='m-0 browser-link' href={profileUrl + cardId} target='_new'>
                            Open your profile page in browser <i className='fal fa-link'></i>
                        </a>
                    </div>
                    <hr />
                    <div className='social-unit py-2'>
                        <h6 className='share-title'>Share on social media</h6>
                        <div class="d-flex mb-4 mt-3">
                            <button class="btn btn-phoenix-primary btn-icon me-2 fbtn-sc">
                                <span class="fab fa-facebook text-primary"></span>
                            </button>
                            <button class="btn btn-phoenix-primary btn-icon me-2">
                                <span class="fab fa-twitter text-info"></span>
                            </button>
                            <button class="btn btn-phoenix-primary btn-icon me-2">
                                <span class="fab fa-whatsapp text-success"></span>
                            </button>
                            <button class="btn btn-phoenix-primary btn-icon me-2">
                                <span class="fab fa-linkedin text-info"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
