import { createSlice } from '@reduxjs/toolkit'

export const productSlice = createSlice({
    name: 'product',
    initialState: {
        products : [],
        product  : {},
        result   : '',
    },
    reducers: {
        getProductsReducer: (state, action) => {
            state.products = action.payload;
        },
        getProductReducer: (state, action) => {
            state.product  = action.payload.data;
        },
        addResultReducer: (state, action) => {
            state.result = action.payload.success
        },
    },
});

export const { getProductsReducer, getProductReducer, addResultReducer } = productSlice.actions;
export default productSlice.reducer;
