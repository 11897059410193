import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import * as yup from "yup";
import { connect, useDispatch } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { PencilIcon, SearchIcon, TrashIcon } from '../../../app/assets/admin/icons/icons';
import { getServices, addService, deleteService, getService, updateService } from '../../../app/actions/user/serviceActions';
import Modal from 'react-bootstrap/Modal';
import { getCard } from '../../../app/actions/user/cardActions';
import { DeleteModal } from '../../../app/components/admin/modals';
import Pagination from '../../../app/components/admin/Pagination';
import { API_URL } from '../../../app/globals';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { serviceFileUpload } from '../../../app/actions/user/fileActions';

const Service = (props) => {
  const [services, setServices]       = useState([]);
  const [card, setCard]               = useState([]);
  const [isLoading, setLoading]       = useState(true);
  const [serviceImg, setServiceImg]   = useState('');
  const [imgPre, setImgPre]           = useState('');
  const [submited, setSubmited]       = useState(false);
  const [modalShow, setModalShow]     = useState(false);
  const [deleteMShow, setDeleteMShow] = useState(false);
  const [actionType, setActionType]   = useState('');
  const [id, setId]                   = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const dispatch = useDispatch();

  useEffect(() => { 
    dispatch(getServices());
    dispatch(getCard());
  }, []);
  
  useEffect(() => { 
      if (props.result) {
        setSubmited(false);
        notifyUpdate();
        setModalShow(false);
      }
  }, [props.result]);

  useEffect(() => {
    setCard(props.card[0]);
  }, [props.card[0]]);

  useEffect(() => { setServices(props.service.data) }, [props.service]);

  useEffect(() => {
    dispatch(getServices(null, searchQuery));
  }, [searchQuery]);

  const handlePagination = (page = null) => {
    dispatch(getServices(page, searchQuery));
  }

  const handleHideModal       = () => setModalShow(false);
  const handleHideDeleteModal = () => setDeleteMShow(false);

  const handleShowModal = (e, id=null) => {
    if (e.target.getAttribute('name') === 'add') setActionType('add');

    if (e.target.getAttribute('name') === 'update') setActionType('update');

    if (e.target.getAttribute('name') === 'update') {
        dispatch(getService(id));
        setId(id);
    }

    setModalShow(true)
}

const handleServiceImg = (e) => {
  const file = e.target.files[0];
  setServiceImg(file);
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImgPre(e.target.result);
    };
    reader.readAsDataURL(file);
  }
};

const handleSubmitImg = (id) => {
  const data = {
    id    : id,
    image : serviceImg,
    name  : 'image',
    title : 'service',
  }
  console.log(data);
  dispatch(serviceFileUpload(data));
}

  const schema = yup.object({
    name       : yup.string().required("Name is required"),
    description: yup.string().required("Description is required"),
    image      : yup.string().optional(),
    serviceUrl : yup.string().required("Service url is required").url(),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = data => {
    
    data = {...data, cardId: card.id}

    setSubmited(true);

    // const requestBody = actionType === 'add' ? {data} : {data, id}

    if (actionType === 'update') {
        dispatch(updateService({data, id}));
    } else {
        dispatch(addService(data));
    }

    dispatch(getServices);
    setSubmited(false);
    handleHideModal();
}


  const notifyUpdate = () => toast.success('Success Confirmed. Thanks!',
    {
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    }
  );

  const ExchangeModal = () => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow} onHide={handleHideModal}
      >
        <Modal.Body>
          <div className='m-3'>
            <h5 className='text-900 fw-semi-bold'>
              {actionType === 'add' ? 'Add New Service' : 'Update this service'}
            </h5>
            <hr />
            <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
              <div className='modal-body px-2'>
                <div className='row'>
                  <div class="custom-input mb-2">
                    <label class="text-body-highlight fw-bold">Name</label>
                    <input className={
                      `form-control `
                      + `${errors.hasOwnProperty('name') ? 'is-invalid' : ''}`
                    }
                      placeholder='Type service name'
                      {...register('name')}
                    />
                    <div className="invalid-feedback">
                      {errors.name?.message}
                    </div>
                  </div>
                  <div class="custom-input mb-2">
                    <label class="text-body-highlight fw-bold">Service URL</label>
                    <input className={
                      `form-control `
                      + `${errors.hasOwnProperty('serviceUrl') ? 'is-invalid' : ''}`
                    }
                      placeholder='Enter the service url'
                      {...register('serviceUrl')}
                    />
                    <div className="invalid-feedback">
                      {errors.serviceUrl?.message}
                    </div>
                  </div>
                  <div class="custom-input mb-2">
                    <label class="text-body-highlight fw-bold mb-2">Description</label>
                    <textarea className={
                      `form-control `
                      + `${errors.hasOwnProperty('description') ? 'is-invalid' : ''}`
                    }
                      placeholder='Describe about your service here'
                      rows="10"
                      {...register('description')}
                    ></textarea>
                    <div className="invalid-feedback">
                      {errors.description?.message}
                    </div>
                  </div>
                  {
                    actionType === 'update' ?
                      <>
                        <label class="text-body-highlight fw-bold mb-1">Image</label>
                        <div class="col-md-8 service-card mt-2 img-usec">
                          <div class="alert alert-outline-secondary" role="alert">
                            <div className='d-flex align-items- mb-3'>
                              <input className={
                                `form-control cust-finput `
                                + `${errors.hasOwnProperty('image') ? 'is-invalid' : ''}`
                              }
                              id="customFile" type="file" accept="image/*"
                              onChange={handleServiceImg}
                                {...register('image')}
                              />
                              <div className="invalid-feedback">
                                {errors.image?.message}
                              </div>
                              <button
                                class="btn btn-outline-secondary me-1"
                                onClick={handleSubmitImg(18)}
                              >
                                Upload
                              </button>
                            </div>
                            <p className='m-0 sub-stitle mb-0'>
                              <span>Upload Your Image:</span> Click the button to upload your product image using our separate image upload service.
                            </p>
                          </div>
                        </div>
                      </>
                      : ''
                  }
                </div>
              </div>

              <div class="col-12 px-2">
                <div class="justify-content-start d-flex">
                  <button class="btn btn-primary me-1 mb-1">
                    {submited ?
                      <>
                        <span className="spinner-border custom-spinner-border-sm" role="status" aria-hidden="true"></span>
                        &#160;Please wait...
                      </>
                      :
                      <>{actionType === 'add' ? 'Save' : 'Update'}</>
                    }
                  </button>
                  <button class="btn btn-phoenix-secondary me-1 mb-1" type="button" onClick={handleHideModal}>Cancel</button>
                </div>
              </div>
            </form>
            <span className='tc-cls'>By clicking the '<b>Send</b>' button, I consent to being contacted by the proprietor of this business card.</span>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  const handleShowDeleteModal = (id) => {
    setId(id);
    setDeleteMShow(true);
  }

  const handleDelete = () => {
    dispatch(deleteService(id))
    .then(notifyUpdate());
    setId(null);
    setDeleteMShow(false);
  }

  const total       = props.service.meta? props.service.meta.total : 0;
  const perPage     = props.service.meta? props.service.meta.perPage : 0;
  const lastPage    = props.service.meta? props.service.meta.lastPage : 0;
  const currentPage = props.service.meta? props.service.meta.currentPage : 0;
  const from        = props.service.meta? props.service.meta.from : 0;

  return (
    <div className="mb-9">
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <ExchangeModal />
      <DeleteModal 
        hide={handleHideDeleteModal}
        show={deleteMShow}
        action={handleDelete}
      />
      <div className="row g-3 mb-4">
        <div className="col-auto">
          <h2 className="mb-2">Services</h2>
          <h5 className="text-700 fw-semi-bold">Here’s what’s going on at your business right now</h5>
        </div>
      </div>
      <div id="products">
        <div className="mb-4">
          <div className="row g-3">
            <div className="col-auto">
              <div className="search-box">
                <form className="position-relative" data-bs-toggle="search" data-bs-display="static">
                  <input className="form-control search-input search"
                    type="search"
                    placeholder="Search services"
                    aria-label="Search"
                    onChange={e => setSearchQuery(e.target.value.trim())}
                  />
                  <span className="d-contents">
                    <SearchIcon />
                  </span>
                </form>
              </div>
            </div>

            <div className="col-auto">
              <a
                className="btn btn-primary d-i-flex"
                id="addBtn"
                name="add"
                onClick={e => handleShowModal(e)}
              >
                Add Service
              </a>
            </div>
          </div>
        </div>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
        <div className='col-12 mt-2'>
            <div className='row service-sec'>
              {
                services && services.length > 0 ?
                  services.map((service) => (
                    <div class="col-md-3 service-card mt-2">
                      <div class="alert alert-outline-secondary d-flex align-items-" role="alert">
                        <img
                          class="service-img"
                          src={`${API_URL}/images/${service?.image}`} alt=""
                          name='update'
                          onClick={handleShowModal}
                        />
                        <div>
                          <p class="m-0 flex-1 stitle">{service.name}</p>
                          <span class="m-0 sub-stitle">{service.description}</span>
                        </div>
                        <div
                        className='dlt-icon'
                        onClick={() => handleShowDeleteModal(service.id)}
                        >
                          <TrashIcon />
                        </div>
                      </div>
                    </div>
                  ))
                  : 'No Data'
              }
            </div>
          </div>
          <div className="row align-items-center justify-content-between py-2 pe-0 fs--1">
            <div className="col-auto d-flex">
              <p className="mb-0 d-none d-sm-block fw-semi-bold text-900" data-list-info="data-list-info"></p>
              <a className="fw-semi-bold" href="#!" data-list-view="*">View all
                <span className="fas fa-angle-right ms-1" data-fa-transform="down-1"></span>
              </a>
              <a className="fw-semi-bold d-none" href="#!" data-list-view="less">View Less
                <span className="fas fa-angle-right ms-1" data-fa-transform="down-1"></span>
              </a>
            </div>
            <div className="col-auto d-flex">
              <Pagination
                currentPage={currentPage}
                lastPage={lastPage}
                total={total}
                perPage={perPage}
                from={from}
                callFunction = {handlePagination}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  const service    = state.services.services;
  const result     = state.contacts.result;
  const card       = state.cards.card;
  const cardResult = state.cards.result;

  return { service, result, card, cardResult };
}

export default connect(mapStateToProps)(Service);