import React from "react";
import axios from 'axios';
import CustomRoutes from './app/route';
import { API_URL } from './app/globals';

export default function App() {
    axios.defaults.baseURL = API_URL + '/api/v1.0';

    return (<CustomRoutes />);
}
