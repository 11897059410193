import React, { useState, useEffect} from 'react';
import { ShareIcon } from '../../app/assets/admin/icons/icons';
import Modal from 'react-bootstrap/Modal';
import { ShareCard } from './components/shareCard';
import { getCards } from "../../app/actions/user/cardActions";
import { connect, useDispatch } from "react-redux";

function MyCards(props) {
  const [modalShow, setModalShow]   = useState(false);
  const [cards, setCards]           = useState([]);
  const [show, setShow]             = useState(false);
  const [loading, setLoading]       = useState(true);
  const [actionType, setActionType] = useState('');
  const [id, setId]                 = useState(null);

  const dispatch = useDispatch();

  useEffect(() => { dispatch(getCards()); }, []);

  useEffect(() => {
    setCards(props.cards);

    // if (props.result === 'success') {
    //   setLoading(false);
    // }
  }, [props.cards]);
  
  const handleHideModal = () => setModalShow(false);
  const handleShowModal = () => setModalShow(true);

  const shareModal = () => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow} onHide={handleHideModal}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <ShareCard />
        </Modal.Body>
      </Modal>
    )
  }

  const myCards = () => {
    return (
      <>
        {
          cards.map((card) => (
            <div class="col-md-4 col-xxl-3 mb-3">
              <div className="card h-100">
                <span className='text-primary shareicon-in-card'>
                  <div onClick={handleShowModal}>
                    <ShareIcon />
                  </div>
                </span>
                <div className="card-body d-flex flex-column">
                  <div className="d-flex justify-content-between">
                    <div className='card-detail-dash'>
                      <div class="avatar avatar-2xl">
                        <img class="rounded-circle " src="https://shorturl.at/fEMN3" alt="" />
                      </div>
                      <div className='user-detail'>
                        <h5 className="mb-2">{card.fullName}</h5>
                        <h6 className="text-700">{card.jobTitle}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="d-flex align-items-center mb-2">
                      <div className="bullet-item bg-primary me-2"></div>
                      <h6 className="text-900 fw-semi-bold flex-1 mb-0">Total visitors</h6>
                      <h6 className="text-900 fw-semi-bold mb-0">234</h6>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="bullet-item bg-primary me-2"></div>
                      <h6 className="text-900 fw-semi-bold flex-1 mb-0">Exchanged contacts</h6>
                      <h6 className="text-900 fw-semi-bold mb-0">70</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </>
    )
  }
  
  return (
    <>
      <div className="mb-9">
        <div className="row g-3 mb-4">
          <div className="col-auto">
            <h2 className="mb-2">My Cards</h2>
            <h5 className="text-700 fw-semi-bold">Here’s what’s going on at your business right now</h5>
          </div>
        </div>
        <div class="row mb-6 user-cards">
          { myCards() }
          <div class="col-md-4 col-xxl-3 mb-3">
            <div className="card h-100 dash-bdr">
              <div className="card-body create-card">
                <h6 className="text-700"><i class="fas fa-plus"></i> Create new card</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='share-modal'>
        {shareModal()}
      </div>
    </>
  );
}

function mapStateToProps(state) {
  const result = state.cards.result;
  const cards  = state.cards.cards;

  return { cards, result };
}

export default connect(mapStateToProps)(MyCards);