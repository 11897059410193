import { createSlice } from '@reduxjs/toolkit'

export const serviceSlice = createSlice({
    name: 'service',
    initialState: {
        services : [],
        service  : {},
        result   : '',
    },
    reducers: {
        getServicesReducer: (state, action) => {
            state.services = action.payload;
        },
        getServiceReducer: (state, action) => {
            state.service  = action.payload.data;
        },
        addResultReducer: (state, action) => {
            state.result = action.payload.success
        },
    },
});

export const { getServicesReducer, getServiceReducer, addResultReducer } = serviceSlice.actions;
export default serviceSlice.reducer;
