import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import * as yup from "yup";
import { connect, useDispatch } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { PencilIcon, SearchIcon, TrashIcon } from '../../../app/assets/admin/icons/icons';
import { getContacts, addContact, deleteContact } from '../../../app/actions/user/contactActions';
import Modal from 'react-bootstrap/Modal';
import { getCard } from '../../../app/actions/user/cardActions';
import { DeleteModal } from '../../../app/components/admin/modals';
import Pagination from '../../../app/components/admin/Pagination';

const Contact = (props) => {
  const [contacts, setContacts]       = useState([]);
  const [card, setCard]               = useState([]);
  const [isLoading, setLoading]       = useState(true);
  const [submited, setSubmited]       = useState(false);
  const [modalShow, setModalShow]     = useState(false);
  const [deleteMShow, setDeleteMShow] = useState(false);
  const [id, setId]                   = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const dispatch = useDispatch();

  useEffect(() => { 
    dispatch(getContacts());
    dispatch(getCard());
  }, []);
  
  useEffect(() => { 
      if (props.result) {
        setSubmited(false);
        notifyUpdate();
        setModalShow(false);
      }
  }, [props.result]);

  useEffect(() => {
    setCard(props.card[0]);
  }, [props.card[0]]);

  useEffect(() => { setContacts(props.contact.data) }, [props.contact]);

  useEffect(() => {
    dispatch(getContacts(null, searchQuery));
  }, [searchQuery]);

  const handlePagination = (page = null) => {
    dispatch(getContacts(page, searchQuery));
  }

  const handleHideModal       = () => setModalShow(false);
  const handleShowModal       = () => setModalShow(true);
  const handleHideDeleteModal = () => setDeleteMShow(false);

  const schema = yup.object({
    name   : yup.string().required("Name is required"),
    company: yup.string().optional(),
    email  : yup.string().required("Email is required").email(),
    phone  : yup.string().matches(/^\+\d{1,3}\d{6,14}$/, "must start with + followed by country code").required('Phone number is required'),
    notes  : yup.string().optional(),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = data => {
    setSubmited(true);
    dispatch(addContact({...data, userId: card.userId, cardId: card.id }));
  }


  const notifyUpdate = () => toast.success('Success Confirmed. Thanks!',
    {
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    }
  );

  const ExchangeModal = () => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow} onHide={handleHideModal}
      >
        <Modal.Body>
          <div className='m-3'>
            <h5 className='text-900 fw-semi-bold'>Exchange your details with Kabilraj</h5>
            <hr />
            <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
              <div className='modal-body px-2'>
                <div className='row'>
                  <div class="custom-input mb-2">
                    <label class="text-body-highlight fw-bold">Name</label>
                    <input className={
                      `form-control `
                      + `${errors.hasOwnProperty('name') ? 'is-invalid' : ''}`
                    }
                      placeholder='Type your name'
                      {...register('name')}
                    />
                    <div className="invalid-feedback">
                      {errors.name?.message}
                    </div>
                  </div>
                  <div class="custom-input mb-2">
                    <label class="text-body-highlight fw-bold">Company</label>
                    <input className={
                      `form-control `
                      + `${errors.hasOwnProperty('company') ? 'is-invalid' : ''}`
                    }
                      placeholder='Type your name'
                      {...register('company')}
                    />
                    <div className="invalid-feedback">
                      {errors.company?.message}
                    </div>
                  </div>
                  <div class="custom-input mb-2">
                    <label class="text-body-highlight fw-bold">Email</label>
                    <input className={
                      `form-control `
                      + `${errors.hasOwnProperty('email') ? 'is-invalid' : ''}`
                    }
                      placeholder='Enter your working email'
                      {...register('email')}
                    />
                    <div className="invalid-feedback">
                      {errors.email?.message}
                    </div>
                  </div>
                  <div class="custom-input mb-2">
                    <label class="text-body-highlight fw-bold">Phone</label>
                    <input className={
                      `form-control `
                      + `${errors.hasOwnProperty('phone') ? 'is-invalid' : ''}`
                    }
                      placeholder='Enter your working phone number'
                      {...register('phone')}
                    />
                    <div className="invalid-feedback">
                      {errors.phone?.message}
                    </div>
                  </div>
                  <div class="custom-input mb-2">
                    <label class="text-body-highlight fw-bold mb-2">Message</label>
                    <textarea className={
                      `form-control `
                      + `${errors.hasOwnProperty('notes') ? 'is-invalid' : ''}`
                    }
                      placeholder='Enter your message'
                      rows="8"
                      {...register('notes')}
                    ></textarea>
                    <div className="invalid-feedback">
                      {errors.notes?.message}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 px-2">
                <div class="justify-content-start d-flex">
                  <button class="btn btn-primary me-1 mb-1">
                    {submited ?
                      <>
                        <span className="spinner-border custom-spinner-border-sm" role="status" aria-hidden="true"></span>
                        &#160;Sending...
                      </>
                      :
                      <>Send</>
                    }
                  </button>
                  <button class="btn btn-phoenix-secondary me-1 mb-1" type="button" onClick={handleHideModal}>Cancel</button>
                </div>
              </div>
            </form>
            <span className='tc-cls'>By clicking the '<b>Send</b>' button, I consent to being contacted by the proprietor of this business card.</span>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  const handleShowDeleteModal = (id) => {
    setId(id);
    setDeleteMShow(true);
  }

  const handleDelete = () => {
    dispatch(deleteContact(id))
    .then(notifyUpdate());
    setId(null);
    setDeleteMShow(false);
  }

  const total       = props.contact.meta? props.contact.meta.total : 0;
  const perPage     = props.contact.meta? props.contact.meta.perPage : 0;
  const lastPage    = props.contact.meta? props.contact.meta.lastPage : 0;
  const currentPage = props.contact.meta? props.contact.meta.currentPage : 0;
  const from        = props.contact.meta? props.contact.meta.from : 0;

  return (
    <div className="mb-9">
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <ExchangeModal />
      <DeleteModal 
        hide={handleHideDeleteModal}
        show={deleteMShow}
        action={handleDelete}
      />
      <div className="row g-3 mb-4">
        <div className="col-auto">
          <h2 className="mb-2">Contacts</h2>
          <h5 className="text-700 fw-semi-bold">Here’s what’s going on at your business right now</h5>
        </div>
      </div>
      <div id="products">
        <div className="mb-4">
          <div className="row g-3">
            <div className="col-auto">
              <div className="search-box">
                <form className="position-relative" data-bs-toggle="search" data-bs-display="static">
                  <input className="form-control search-input search"
                    type="search"
                    placeholder="Search products"
                    aria-label="Search"
                    onChange={e => setSearchQuery(e.target.value.trim())}
                  />
                  <span className="d-contents">
                    <SearchIcon />
                  </span>
                </form>
              </div>
            </div>

            {/* <div className="col-auto">
              <a
                className="btn btn-primary d-i-flex"
                id="addBtn" onClick={handleShowModal}
              >
                Add contact
              </a>
            </div> */}
          </div>
        </div>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <div className="table-responsive scrollbar mx-n1 px-1 contact-table">
            <table className="table fs--1 mb-0">
              <thead>
                <tr>
                  <th className="white-space-nowrap fs--1 align-middle ps-0">
                    <div className="form-check mb-0 fs-0">
                      <input className="form-check-input" id="checkbox-bulk-products-select" type="checkbox" />
                    </div>
                  </th>
                  <th className="sort align-middle ps-4" scope="col" data-sort="vendor">NAME</th>
                  <th className="sort align-middle ps-4" scope="col" data-sort="vendor">EMAIL</th>
                  <th className="sort align-middle ps-4" scope="col" data-sort="category">PHONE</th>
                  <th className="sort align-middle ps-4" scope="col" data-sort="vendor">CARD</th>
                  <th className="sort align-middle ps-4" scope="col" data-sort="vendor">NOTES</th>
                  <th className="sort text-end align-middle pe-0 ps-4" scope="col">ACTIONS</th>
                </tr>
              </thead>
              <tbody className="list" id="products-table-body">
                {
                  contacts && contacts.length > 0 ?
                    contacts.map((contact) => (
                      <>
                        <tr className="position-static">
                          <td className="fs--1 align-middle">
                            <div className="form-check mb-0 fs-0">
                              <input className="form-check-input" type="checkbox" />
                            </div>
                          </td>
                          <td className="price align-middle white-space-nowrap text-end fw-bold text-700 ps-4">
                            {contact.name}
                          </td>
                          <td className="price align-middle white-space-nowrap text-end fw-bold text-700 ps-4">
                            {contact.email}
                          </td>
                          <td className="price align-middle white-space-nowrap text-end fw-bold text-700 ps-4">
                            {contact.phone}
                          </td>
                          <td className="price align-middle white-space-nowrap text-end fw-bold text-700 ps-4">
                            GJDSHFJEU6473R
                          </td>
                          <td className="price align-middle white-space-nowrap text-end fw-bold text-700 ps-4">
                            {contact.notes}
                          </td>
                          <td className="align-middle white-space-nowrap text-end pe-0 ps-4 btn-reveal-trigger">
                            <div className="font-sans-serif btn-reveal-trigger position-static">
                              <a href='/'
                                className="btn btn-sm action-btn" type="button">
                                <PencilIcon />
                              </a>
                              <a 
                                className="btn btn-sm action-btn"
                                type="button"
                                onClick={() => handleShowDeleteModal(contact.id)}
                              >
                                <TrashIcon />
                              </a>
                            </div>
                          </td>
                        </tr>
                      </>
                    ))
                    : 'No Data'
                }
              </tbody>
            </table>
          </div>
          <div className="row align-items-center justify-content-between py-2 pe-0 fs--1">
            <div className="col-auto d-flex">
              <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900" data-list-info="data-list-info"></p>
              <a className="fw-semi-bold" href="#!" data-list-view="*">View all
                <span className="fas fa-angle-right ms-1" data-fa-transform="down-1"></span>
              </a>
              <a className="fw-semi-bold d-none" href="#!" data-list-view="less">View Less
                <span className="fas fa-angle-right ms-1" data-fa-transform="down-1"></span>
              </a>
            </div>
            <div className="col-auto d-flex">
              <Pagination
                currentPage={currentPage}
                lastPage={lastPage}
                total={total}
                perPage={perPage}
                from={from}
                callFunction = {handlePagination}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  const contact    = state.contacts.contacts;
  const result     = state.contacts.result;
  const card       = state.cards.card;
  const cardResult = state.cards.result;

  return { contact, result, card, cardResult };
}

export default connect(mapStateToProps)(Contact);