const Pagination = (props) => {
    const { currentPage, lastPage, total, perPage, callFunction, from } = props;

    const handleFetch = (page) => { 
        callFunction(page); 
    }

    const pages = () => {
        const pageItems = [];
        const totalPages = Math.ceil(total / perPage);
        let startPage = Math.max(1, currentPage - 2);
        let endPage = Math.min(totalPages, currentPage + 2);

        if (endPage - startPage < 4) {
            if (startPage === 1) {
                endPage = Math.min(totalPages, 5);
            } else {
                startPage = Math.max(1, totalPages - 4);
            }
        }

        pageItems.push(
            currentPage !== 1 ? (
                <li key="prev" className="page-item pgq-btn">
                    <a href="#" className="page-link" onClick={(e) => { e.preventDefault(); handleFetch(currentPage - 1) }}>Previous</a>
                </li>
            ) : null
        );

        for (let i = startPage; i <= endPage; i++) {
            pageItems.push(
                <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
                    <a
                        href="#"
                        className={`page-link ${i === currentPage ? 'active-link' : ''}`}
                        onClick={(e) => { e.preventDefault(); handleFetch(i) }}
                    >
                        {i}
                    </a>
                </li>
            );
        }

        pageItems.push(
            currentPage !== totalPages ? (
                <li key="next" className="page-item pgq-btn">
                    <a href="#" className="page-link" onClick={(e) => { e.preventDefault(); handleFetch(currentPage + 1) }}>Next</a>
                </li>
            ) : null
        );

        return pageItems;
    }

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination">{pages()}</ul>
        </nav>
    );
}

export default Pagination;
