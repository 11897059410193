import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';
import { ShareCard } from '../components/shareCard';
import * as yup from "yup";
import { connect, useDispatch } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import isEmptyObject from '../../../app/helper/isEmptyObject';
import { deleteSocialLink, getCard, updateCard } from '../../../app/actions/user/cardActions';
import { fileUpload } from '../../../app/actions/user/fileActions';
import { API_URL } from '../../../app/globals';

const Customize = (props) => {
  const [profileImg, setProfileImg]     = useState('');
  const [profilePre, setProfilePre]     = useState('');
  const [coverPre, setCoverPre]         = useState('');
  const [coverImg, setCoverImg]         = useState('');
  const [tabMenu, setTabMenu]           = useState('tabA');
  const [linkBaseUrl, setLinkBaseUrl]   = useState('');
  const [selectedLink, setSelectedLink] = useState(null);
  const [card, setCard]                 = useState([]);
  const [isLoading, setLoading]         = useState(true);
  const [submited, setSubmited]         = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getCard());
    }, []);

    useEffect(() => {
        setCard(props.card[0]);
        if (props.result) {
          setLoading(false);
          setSubmited(false);
        }
    }, [props.card[0]]);

    useEffect(() => {
        if (props.upResult) {
          setLoading(false);
          setSubmited(false);
          notifyUpdate();
        }
    }, [props.upResult]);
   
    useEffect(() => {
      if (props.uploadResult) {
        setProfileImg('');
        setCoverImg('');
        notifyUpdate();
      }
    }, [props.uploadResult]);

    const schema = yup.object({
        firstName: yup.string().required("First name is required"),
        lastName : yup.string().required("Last name is required"),
        slug     : yup.string().optional(),
        jobTitle : yup.string().required("Posstion or Tagline is required"),
        company  : yup.string().optional(),
        email    : yup.string().required("Email is required").email(),
        phone    : yup.number().required("Phone number is required").typeError("Phone number must be a number"),
        cardTitle: yup.string().required("Card title is requried"),
        address  : yup.string().optional(),
        about    : yup.string().optional(),
      });

    const { register, handleSubmit, setValue, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
      if (!isEmptyObject(props.card)) {
        setValue('firstName', card.firstName);
        setValue('lastName', card.lastName);
        setValue('slug', card.slug);
        setValue('jobTitle', card.jobTitle);
        setValue('company', card.company);
        setValue('email', card.email);
        setValue('phone', card.phone);
        setValue('cardTitle', card.cardTitle);
        setValue('address', card.address);
        setValue('about', card.about);
    }
    }, [card]);

    const onSubmit  = data => {
        setSubmited(true);
        dispatch(updateCard(card.id, data));
    }
    

  const notifyUpdate = () => toast.success('Success Confirmed. Thanks!',
    {
      // icon: '👏',
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    }
  );

  const socialMediaOptions = [
    { value: 'web',       label: 'Website',   icon: 'far fa-globe',     baseUrl: 'https://' },
    { value: 'whatsapp',  label: 'Whatsapp',  icon: 'fab fa-whatsapp',  baseUrl: 'https://wa.me/' },
    { value: 'facebook',  label: 'Facebook',  icon: 'fab fa-facebook',  baseUrl: 'https://facebook.com/' },
    { value: 'instagram', label: 'Instagram', icon: 'fab fa-instagram', baseUrl: 'https://instagram.com/' },
    { value: 'linkedin',  label: 'Linkedin',  icon: 'fab fa-linkedin',  baseUrl: 'https://linkedin/' },
    { value: 'twitter',   label: 'Twitter',   icon: 'fab fa-twitter',   baseUrl: 'https://twitter/' },
  ];

  const SocialMediaSelect = ({ selectedOption, onChange }) => {
    return (
      <Select
        value={selectedOption}
        onChange={onChange}
        options={socialMediaOptions}
        isClearable={false}
        isSearchable={false}
        getOptionLabel={(option) => (
          <>
            <i className={option.icon}></i> {option.label}
          </>
        )}
        getOptionValue={(option) => option.value}
      />
    );
  };

  const handleSocialMediaChange = (selectedOption) => {
    setSelectedLink(selectedOption);
    setLinkBaseUrl(selectedOption.baseUrl);
  };

  const handleProfileImg = (e) => {
    const file = e.target.files[0];
    setProfileImg(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfilePre(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmitProfileImg = () => {
    const data = {
      cardId: card.id,
      image : profileImg,
      name  : 'profileImg',
      title : 'profile',
    }
    dispatch(fileUpload(data));
  }

  const handleCoverImg = (e) => {
    const file = e.target.files[0];
    setCoverImg(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setCoverPre(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmitCoverImg = () => {
    const data = {
      cardId: card.id,
      image : coverImg,
      name  :'coverImg',
      title : 'cover',
    }
    dispatch(fileUpload(data));
  }
  
  const handleDeleteSocial = (id, key) => {
    dispatch(deleteSocialLink(id, key))
    dispatch(getCard())
  }

  const handleSocialUrl = (e) => {
    selectedLink.baseUrl = e.target.value;
  }

  const handleAddSocial = (id) => {
    const updatedLinks = [...card.links, selectedLink];
    const data = {links: updatedLinks}
    dispatch(updateCard(id, data))
  }

  const basicDetailForm = () => {
    return (
      <div className='sprd-column p-3'>
        <div className='customize-form'>
          <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
            <div class="col-md-6">
              <label class="form-label">First name <span>*</span></label>
              <input
                className={
                  `form-control `
                  + `${errors.hasOwnProperty('firstName') ? 'is-invalid' : ''}`
                }
                placeholder='Your first name'
                {...register('firstName')}
              />
              <div className="invalid-feedback">
                {errors.firstName?.message}
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-label">Last name <span>*</span></label>
              <input
                className={
                  `form-control `
                  + `${errors.hasOwnProperty('lastName') ? 'is-invalid' : ''}`
                }
                placeholder='Your last name'
                {...register('lastName')}
              />
              <div className="invalid-feedback">
                {errors.lastName?.message}
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-label">Position or Tagline <span>*</span></label>
              <input
                className={
                  `form-control `
                  + `${errors.hasOwnProperty('jobTitle') ? 'is-invalid' : ''}`
                }
                placeholder='Your position or tagline'
                {...register('jobTitle')}
              />
              <div className="invalid-feedback">
                {errors.jobTitle?.message}
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-label">Company</label>
              <input
                className={
                  `form-control `
                  + `${errors.hasOwnProperty('company') ? 'is-invalid' : ''}`
                }
                placeholder={`You're currently working on`}
                {...register('company')}
              />
              <div className="invalid-feedback">
                {errors.company?.message}
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-label">Email <span>*</span></label>
              <input
                className={
                  `form-control `
                  + `${errors.hasOwnProperty('email') ? 'is-invalid' : ''}`
                }
                placeholder={'Your working email'}
                {...register('email')}
              />
              <div className="invalid-feedback">
                {errors.email?.message}
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-label">Username <span>*</span></label>
              <input
                className={
                  `form-control slug-input`
                  + `${errors.hasOwnProperty('slug') ? 'is-invalid' : ''}`
                }
                disabled
                {...register('slug')}
              />
              <div className="invalid-feedback">
                {errors.slug?.message}
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-label">Phone <span>*</span></label>
              <input
                className={
                  `form-control `
                  + `${errors.hasOwnProperty('phone') ? 'is-invalid' : ''}`
                }
                placeholder={'Your working phone number'}
                {...register('phone')}
              />
              <div className="invalid-feedback">
                {errors.phone?.message}
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-label">Card name <span>*</span></label>
              <input
                className={
                  `form-control `
                  + `${errors.hasOwnProperty('cardTitle') ? 'is-invalid' : ''}`
                }
                placeholder={'Enter this card name'}
                {...register('cardTitle')}
              />
              <div className="invalid-feedback">
                {errors.cardTitle?.message}
              </div>
            </div>
            <div class="col-12">
              <label class="form-label">Address</label>
              <input
                className={
                  `form-control `
                  + `${errors.hasOwnProperty('address') ? 'is-invalid' : ''}`
                }
                placeholder={'Your current address'}
                {...register('address')}
              />
              <div className="invalid-feedback">
                {errors.address?.message}
              </div>
            </div>
            <div class="col-12">
              <label class="form-label">About you</label>
              <textarea
                className={
                  `form-control `
                  + `${errors.hasOwnProperty('about') ? 'is-invalid' : ''}`
                }
                placeholder={'Describe about you'}
                rows="8"
                {...register('about')}
              ></textarea>
              <div className="invalid-feedback">
                {errors.about?.message}
              </div>
            </div>
            <div class="col-6">
              {
                profileImg? 
                <>
                  <label class="form-label">Profile</label>
                  <button class="btn btn-secondary me-1 mb-1" onClick={handleSubmitProfileImg} type="button">Chnage</button>
                </>
                :
                <>
                  <label class="form-label">Profile</label>
                  <input type="file" accept="image/*" onChange={handleProfileImg} />
                </>
              }
            </div>
            <div class="col-6">
              {
                coverImg? 
                <>
                  <label class="form-label">Cover image</label>
                  <button class="btn btn-secondary me-1 mb-1" onClick={handleSubmitCoverImg} type="button">Chnage</button>
                </>
                :
                <>
                  <label class="form-label">Cover image</label>
                  <input type="file" accept="image/*" onChange={handleCoverImg} />
                </>
              }
            </div>
            <div class="col-12 gy-6">
              <div class="row g-3 justify-content-end">
                {/* <div class="col-auto"><button class="btn btn-phoenix-primary px-5">Cancel</button></div> */}
                <div class="col-auto">
                  <button class="btn btn-primary px-5 px-sm-8 d-flex">
                    {submited ?
                    <>
                      <span className="spinner-border custom-spinner-border-sm" role="status" aria-hidden="true"></span>
                      &#160;Updating...
                    </>
                    :
                    <>Update changes</>
                    }
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  };

  const addLinksTab = () => {
    return (
      <div className='sprd-column p-3'>
        <div className='customize-form'>
          <div className='row g-3 justtify-c-fs'>
          {
            card && card.links && card.links.map((link) => (
              <div class="col-md-4" key={link.value}>
                <div class="alert alert-outline-secondary d-flex align-items-center" role="alert">
                  <span class={link.icon + ` text-secondary me-3`}></span>
                  <p class="mb-0 flex-1">{link.label}</p>
                  <button
                    class="btn-close"
                    type="button"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() => handleDeleteSocial(card?.id, link.label)}
                  ></button>
                </div>
              </div>
            ))
          }
          </div>
          <form class="row g-3">
            <div class="col-md-6">
              <label class="form-label">Link type <span>*</span></label>
              <SocialMediaSelect
                selectedOption={selectedLink}
                onChange={handleSocialMediaChange}
              />
            </div>
            <div class="col-md-6">
              <label class="form-label">Url <span>*</span></label>
              <input
                class="form-control"
                type="url"
                defaultValue={linkBaseUrl}
                placeholder='https://example.com'
                onChange={(e) => handleSocialUrl(e)}
              />
            </div>
            <div class="col-12 gy-6">
              <div class="row g-3 justify-content-end">
                <div class="col-auto">
                  <button class="btn btn-phoenix-primary px-4">Cancel</button>
                </div>
                <div class="col-auto">
                  <button 
                    class="btn btn-primary px-5 px-sm-5"
                    type='button'
                    onClick={() => handleAddSocial(card?.id)}
                  >
                    Add Link
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  };

  return (
    <div className="mb-9">
      <Toaster
          position="top-right"
          reverseOrder={false}
        />
        {isLoading ? <div className='form-loader'></div> : '' }
      <div className="row g-3 mb-4">
        <div className="col-auto">
          <h2 className="mb-2">Customize</h2>
          <h5 className="text-700 fw-semi-bold">Here’s what’s going on at your business right now</h5>
        </div>
      </div>
      <div className='customize-section'>
        <div className='col-12'>
          <div className='row align-item-fs'>
            <div className='col-12 col-md-5'>
              <div className='sprd-column pb-3'>
                <div className='customize-priview'>
                  <div className='cover-img'
                    style={{
                      backgroundImage: coverPre ? `url(${coverPre})` :
                      `url(${API_URL}/images/${card?.coverImg})`,
                      backgroundSize: 'cover',
                    }}>
                    <div className='profile-card'>
                      <img className='profile-img'
                        src={profilePre ? `${profilePre}` :
                        `${API_URL}/images/${card?.profileImg}`} alt='senisha' />
                    </div>
                  </div>
                  <div className='profile-text mt-8'>
                    <h5 className='flname'>
                      {
                        card? 
                          card.firstName + ' ' + card.lastName : 'Kabilraj Selvanantham'
                      }
                    </h5>
                    <h6 className='role'>
                      {
                        card? 
                          card.jobTitle : 'Software Developer'
                      }
                    </h6>
                    <h6 className='company'>{ card && card.company? `@`+card.company : '' }</h6>
                    <div className='links mt-3'>
                    {
                      card && card.links && card.links.map((link) => (
                        <a href={link.baseUrl} className='item' key={link.value} target='_new'>
                          <i className={link.icon}></i>
                        </a>
                      ))
                    }
                    </div>
                  </div>
                  <div className='info-unit'>
                    <hr className='mb-0' />
                    <div className='contact-info px-3 pt-2'>
                      <p className='info-title mb-2'>Contact info</p>
                      <div className='info'>
                        <div className='info-item'>
                          <i class="far fa-envelope"></i>
                          <span>{card? card.email : 'support@senisha.com'}</span>
                        </div>
                        <div className='info-item'>
                          <i class="far fa-phone"></i>
                          <span>{card? card.phone : '+1 647 6464 983'}</span>
                        </div>
                        <div className='info-item'>
                          <i class="far fa-location-arrow"></i>
                          <span>{card? card.address : '789 Fond Ave, Markham, Ontario'}</span>
                        </div>
                      </div>
                    </div>
                    <hr className='mb-0' />
                    <div className='about px-3 py-2'>
                      <p className='info-title mb-2'>About</p>
                      <div className='about-text'>
                        <span>
                          {
                            card? card.about : `I'm Creative Director and UI/UX Designer from Sydney, Australia,
                            working in web development and print media. I enjoy turning complex
                            problem`
                          }
                        </span>
                      </div>
                    </div>
                    <div className='contact-btn-section mt-3'>
                      <button className='btn btn-primary'>Save contact</button>
                      <button className='btn btn-primary bdr-btn'>Exchange contact</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-7 p-0'>
              <ul class="nav nav-phoenix-pills mb-5 d-sm-none d-xl-flex">
                <li class="nav-item" role="presentation">
                  <a className={
                    `nav-link cursor-pointer
                    ${tabMenu === 'tabA' ? ' active' : ''}`
                  }
                    onClick={e => setTabMenu('tabA')}>
                    Basic info
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a className={
                    `nav-link cursor-pointer
                    ${tabMenu === 'tabB' ? ' active' : ''}`
                  }
                    onClick={e => setTabMenu('tabB')}>
                    Social links
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a className={
                    `nav-link cursor-pointer 
                    ${tabMenu === 'tabC' ? ' active' : ''}`
                  }
                    onClick={e => setTabMenu('tabC')}>
                    Share Card
                  </a>
                </li>
              </ul>
              <div className={
                tabMenu === 'tabA' ? 'show-section' : 'hide-section'}>
                {basicDetailForm()}
              </div>
              <div className={
                tabMenu === 'tabB' ? 'show-section' : 'hide-section'}>
                {addLinksTab()}
              </div>
              <div className={
                tabMenu === 'tabC' ? 'show-section' : 'hide-section'}>
                <ShareCard cardId={card && card.slug}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  const card         = state.cards.card;
  const result       = state.cards.result;
  const upResult     = state.cards.upResult;
  const uploadResult = state.files.result;

  return { card, result, upResult, uploadResult };
}

export default connect(mapStateToProps)(Customize);