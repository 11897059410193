import React, { useState, useEffect } from 'react';
import { getPublicCard } from "../../app/actions/user/cardActions";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from 'react-router';
import GenerateContact from './partials/contact';
import { API_URL } from '../../app/globals';
import Modal from 'react-bootstrap/Modal';
import toast, { Toaster } from 'react-hot-toast';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { addContact } from '../../app/actions/user/contactActions';

const ProfileCard = (props) => {
    const [card, setCard]           = useState([]);
    const [isLoading, setLoading]   = useState(true);
    const [submited, setSubmited]   = useState(false);
    const [modalShow, setModalShow] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleHideModal = () => setModalShow(false);
    const handleShowModal = () => setModalShow(true);

    const schema = yup.object({
        name   : yup.string().required("Name is required"),
        company: yup.string().optional(),
        email  : yup.string().required("Email is required").email(),
        phone  : yup.string().matches(/^\+\d{1,3}\d{6,14}$/, "must start with + followed by country code").required('Phone number is required'),
        notes  : yup.string().optional(),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = data => {
        setSubmited(true);
        dispatch(addContact({ ...data, userId: card.userId, cardId: card.id }));
    }

    const notifyUpdate = () => toast.success('Success Confirmed. Thanks!',
        {
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        }
    );

    const slug = window.location.pathname.split('/').pop();

    useEffect(() => { dispatch(getPublicCard(slug)); }, []);

    useEffect(() => {
        if (props.cResult) {
            setSubmited(false);
            notifyUpdate();
            setModalShow(false);
        }
    }, [props.cResult]);

    useEffect(() => {
        setCard(props.card);

        // if (props.result === 'success') {
        //   setLoading(false);
        // }
    }, [props.card]);

    useEffect(() => {
        if (props.card === null) {
            navigate('/unauthorized')
            window.location.reload(true);
        }
    }, [props.card]);

    const contact = {
        name   : card.firstName,
        company: card.company,
        address: card.address,
        phone  : card.phone,
        email  : card.email,
        cardUrl: window.location.href
    }

    const ExchangeModal = () => {
        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow} onHide={handleHideModal}
            >
                <Modal.Body>
                    <div className='m-3'>
                        <h5 className='text-900 fw-semi-bold'>Exchange your details with Kabilraj</h5>
                        <hr />
                        <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                            <div className='modal-body px-2'>
                                <div className='row'>
                                    <div class="custom-input mb-2">
                                        <label class="text-body-highlight fw-bold">Name</label>
                                        <input className={
                                            `form-control `
                                            + `${errors.hasOwnProperty('name') ? 'is-invalid' : ''}`
                                        }
                                            placeholder='Type your name'
                                            {...register('name')}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.name?.message}
                                        </div>
                                    </div>
                                    <div class="custom-input mb-2">
                                        <label class="text-body-highlight fw-bold">Company</label>
                                        <input className={
                                            `form-control `
                                            + `${errors.hasOwnProperty('company') ? 'is-invalid' : ''}`
                                        }
                                            placeholder='Type your name'
                                            {...register('company')}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.company?.message}
                                        </div>
                                    </div>
                                    <div class="custom-input mb-2">
                                        <label class="text-body-highlight fw-bold">Email</label>
                                        <input className={
                                            `form-control `
                                            + `${errors.hasOwnProperty('email') ? 'is-invalid' : ''}`
                                        }
                                            placeholder='Enter your working email'
                                            {...register('email')}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.email?.message}
                                        </div>
                                    </div>
                                    <div class="custom-input mb-2">
                                        <label class="text-body-highlight fw-bold">Phone</label>
                                        <input className={
                                            `form-control `
                                            + `${errors.hasOwnProperty('phone') ? 'is-invalid' : ''}`
                                        }
                                            placeholder='Enter your working phone number'
                                            {...register('phone')}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.phone?.message}
                                        </div>
                                    </div>
                                    <div class="custom-input mb-2">
                                        <label class="text-body-highlight fw-bold mb-2">Message</label>
                                        <textarea className={
                                            `form-control `
                                            + `${errors.hasOwnProperty('notes') ? 'is-invalid' : ''}`
                                        }
                                            placeholder='Enter your message'
                                            rows="8"
                                            {...register('notes')}
                                        ></textarea>
                                        <div className="invalid-feedback">
                                            {errors.notes?.message}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 px-2">
                                <div class="justify-content-start d-flex">
                                    <button class="btn btn-primary me-1 mb-1">
                                        {submited ?
                                            <>
                                                <span className="spinner-border custom-spinner-border-sm" role="status" aria-hidden="true"></span>
                                                &#160;Sending...
                                            </>
                                            :
                                            <>Send</>
                                        }
                                    </button>
                                    <button class="btn btn-phoenix-secondary me-1 mb-1" type="button" onClick={handleHideModal}>Cancel</button>
                                </div>
                            </div>
                        </form>
                        <span className='tc-cls'>By clicking the '<b>Send</b>' button, I consent to being contacted by the proprietor of this business card.</span>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    return (
        <div className='profileapp'>
            {/* <div className='powerdby-con'>
                <h5>Powerd by:</h5>
                <img className='pwd-logo' src='/logo.png' alt='logo' />
            </div> */}
            {
                !props.result && !card.firstName ?
                    <div className='profile-page-loader'>
                        <img className='loader-logo' src='/logo.png' alt='logo' />
                        <div class="loading-container"></div>
                    </div>
                    :
                    <>
                        <Toaster
                            position="top-right"
                            reverseOrder={false}
                        />
                        <ExchangeModal />
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 center-column p-0">
                                    <div className='sprd-column pb-3'>
                                        <div className='customize-priview'>
                                            <div className='cover-img'
                                                style={{
                                                    backgroundImage: card?.coverImg ? `url(${API_URL}/images/${card?.coverImg})` :
                                                        `url(https://shorturl.at/lOZ07)`,
                                                    backgroundSize: 'cover',
                                                }}>
                                                <div className='profile-card'>
                                                    <img className='profile-img'
                                                        src={card?.profileImg ? `${API_URL}/images/${card?.profileImg}` :
                                                            `https://shorturl.at/fEMN3`} alt='senisha' />
                                                </div>
                                            </div>
                                            <div className='profile-text mt-8'>
                                                <h5 className='flname pb-1'>{card?.firstName + ' ' + card?.lastName}</h5>
                                                <h6 className='role'>{card?.jobTitle}</h6>
                                                <h6 className='company'>{card ? `@` + card.company : ''}</h6>
                                                <div className='links mt-3'>
                                                    {
                                                        card && card.links && card.links.map((link) => (
                                                            <a href={link.baseUrl} className='item' key={link.value} target='_new'>
                                                                <i className={link.icon}></i>
                                                            </a>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <div className='info-unit'>
                                                <hr className='mb-0' />
                                                <div className='contact-info px-3 pt-2'>
                                                    <p className='info-title mb-2'>Contact info</p>
                                                    <div className='info'>
                                                        <div className='info-item'>
                                                            <img src='/images/icons/email.svg' />
                                                            <a href={`mailto:${card?.email}`} className='link-cl'>{card?.email}</a>
                                                        </div>
                                                        <div className='info-item'>
                                                            <img src='/images/icons/phone.svg' />
                                                            <a href={`tel:${card?.phone}`} className='link-cl'>{card?.phone}</a>
                                                        </div>
                                                        <div className='info-item'>
                                                            <img src='/images/icons/location.svg' />
                                                            <a href={`https://www.google.com/maps/search/?api=1&query=${card?.address}`} className='link-cl'>{card?.address}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className='mb-0' />
                                                <div className='about px-3 py-2'>
                                                    <p className='info-title mb-2'>About</p>
                                                    <div className='about-text'>
                                                        <span>{card?.about}</span>
                                                    </div>
                                                </div>
                                                <div className='contact-btn-section mt-3 px-3 text-center fixed-button'>
                                                    <GenerateContact data={contact} />
                                                    <button
                                                        onClick={handleShowModal}
                                                        className='btn btn-primary bdr-btn'>
                                                        Exchange contact
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </div>

    )
}

function mapStateToProps(state) {
    const result  = state.cards.result;
    const cResult = state.contacts.result;
    const card    = state.cards.publicCard;

    return { card, result, cResult };
}

export default connect(mapStateToProps)(ProfileCard);