import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { CloseIcon } from '../../assets/admin/icons/icons'

export const DeleteModal = ({show, hide, action}) => {
    return (
        <Modal centered className="modal fade delete-modal" show={show} onHide={hide}>
            <Modal.Body>
                <div className="container">
                    <div className="text-center">
                        <CloseIcon />
                        <h4>Are you sure?</h4>
                        <p>Do you really want to delete these records? This process cannot be undone.</p>
                    </div>
                    <div className="delete-modal-btn-group">
                        <Button onClick={action} variant="btn btn-delete me-1 mb-1">Yes</Button>
                        <Button variant="btn btn-secondary me-1 mb-1" onClick={hide}>No</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
