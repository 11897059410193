import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import cookie from 'react-cookies';
import axios from 'axios';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

const ForgotPassword = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const verify   = location.state?.from?.pathname || '/verify-email';

    const [submited, setSubmited] = useState(false)

    const schema = yup.object({
        email: yup.string().email().required("Email address is required"),
    });

    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const notify = (message) => toast.error(message,
        {
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        }
    );

    const notifySuccess = (message) => toast.success(message,
        {
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        }
    );
    
    const onSubmit = async (data) => {
        const requestBody = { ...data}
        setSubmited(true)
        try {
            const response = await axios.post('auth/forgot-password', requestBody);
            if (response.status === 200) {
                notifySuccess(response.data.message)
                setSubmited(false)
                // navigate(verify, { replace: true });
            } else {
                notify(response.data.message) // Display error message returned from the server
            }
        } catch (err) {
            console.error("Error:", err);
            if (err.response) {
                notify(err.response.data.message)
            } else {
                notify('An error occurred. Please try again later.')
            }
        }
    }

    return (
        <>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <main class="main" id="top">
                <div class="container-fluid px-0" data-layout="container">
                    <div class="container">
                        <div class="row flex-center min-vh-100">
                            <div class="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-5">
                                <div className='mb-4'>
                                    {/* <img src='/logo.png' /> */}
                                    <h3>Forgot Your Password</h3>
                                    <p>Recover Your Account Access</p>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div class="mb-3 text-start">
                                        <label class="mb-1" for="email">Email address <span className='text-danger'>*</span></label>
                                        <div class="form-icon-container">
                                            <input
                                                type="text"
                                                placeholder="Type your email here"
                                                {...register('email')}
                                                className={
                                                    `form-control form-icon-input auth-input `
                                                    + `${errors.hasOwnProperty('email') ? 'is-invalid' : ''}`
                                                }
                                            />
                                            <span class="fas fa-user text-900 fs--1 form-icon input-icon"></span>
                                            <div className="invalid-feedback">
                                                {errors.email?.message}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <button className="btn btn-primary w-100 mb-3 mt-3">Forgot Password</button> */}
                                    <button class="btn btn-primary w-100 mb-3 mt-3 d-flex text-ceneter f-pass-btn">
                                        {submited ?
                                            <>
                                                <span className="spinner-border custom-spinner-border-sm" role="status" aria-hidden="true"></span>
                                                &#160;Forgot Password
                                            </>
                                            :
                                            <>Forgot Password</>
                                        }
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

function mapStateToProps(state) {
    const auth = state.authenticate.auth;

    return { auth };
}

export default connect(mapStateToProps)(ForgotPassword);
