import { createSlice } from '@reduxjs/toolkit'

export const cardSlice = createSlice({
    name: 'card',
    initialState: {
        cards      : [],
        card       : {},
        publicCard : {},
        result     : '',
        upResult   : '',
    },
    reducers: {
        getCardsReducer: (state, action) => {
            state.cards = action.payload.data;
        },
        getCardReducer: (state, action) => {
            state.card  = action.payload.data;
            state.result = true;
        },
        getPublicCardReducer: (state, action) => {
            state.publicCard  = action.payload.data;
            state.result = true;
        },
        getResultReducer: (state, action) => {
            state.result = action.payload;
        },
        updateResultReducer: (state, action) => {
            state.upResult = action.payload;
        },
    },
});

export const { getCardsReducer, getCardReducer, getPublicCardReducer, getResultReducer, updateResultReducer} = cardSlice.actions;
export default cardSlice.reducer;
