import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        auth:{},
    },
    reducers: {
        autheticateReducer: (state, action) => {
            if (action.payload.result && action.payload.result === 'success') {
                state.auth = {
                    token   : action.payload.token,
                    role    : action.payload.role,
                    ablities: action.payload.ablities,
                    result  : action.payload.result,
                }
            }
        },
    },
});

export const { autheticateReducer } = authSlice.actions;

export default authSlice.reducer;