import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import '../../app/assets/public/css/app.css';
import LanguageProvider from "../../app/context/LanguageProvider";

const PublicLayout = ({ children }) => {

    const [isMainMenueOpen, setMainMenueOpen] = useState(false);

    const handleMainMenuStatus = (isMainMenuOpen) => {
        console.log(isMainMenuOpen)
        setMainMenueOpen(isMainMenuOpen);
    }

    return (
        <LanguageProvider>
            <div className="public-view">
                {/* <Preloader /> */}
                <header class="header-section">
                    {/* <MainHeader handleMainMenueStatus={handleMainMenuStatus} /> */}
                </header>
                <Outlet />
                {/* <Footer /> */}
            </div>
        </LanguageProvider>
    );
}

export default PublicLayout;