import React, { useState } from 'react';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

const Setting = () => {
  const [submited, setSubmited] = useState(false);

  const schema = yup.object({
    firstName: yup.string().required("First name is required"),
    lastName : yup.string().required("Last name is required"),
    slug     : yup.string().optional(),
    jobTitle : yup.string().required("Posstion or Tagline is required"),
    company  : yup.string().optional(),
    email    : yup.string().required("Email is required").email(),
    phone    : yup.number().required("Phone number is required").typeError("Phone number must be a number"),
    cardTitle: yup.string().required("Card title is requried"),
    address  : yup.string().optional(),
    about    : yup.string().optional(),
  });

  const { register, handleSubmit, setValue, formState:{ errors } } = useForm({
      resolver: yupResolver(schema)
  });

  const onSubmit = data => {}

  const settingForm = () => {
    return (
      <div className='sprd-column p-3'>
        <div className='customize-form'>
          <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
            <div class="col-md-12">
              <label class="form-label">First name <span>*</span></label>
              <input
                className={
                  `form-control `
                  + `${errors.hasOwnProperty('firstName') ? 'is-invalid' : ''}`
                }
                placeholder='Your first name'
                {...register('firstName')}
              />
              <div className="invalid-feedback">
                {errors.firstName?.message}
              </div>
            </div>
            <div class="col-md-12">
              <label class="form-label">Last name <span>*</span></label>
              <input
                className={
                  `form-control `
                  + `${errors.hasOwnProperty('lastName') ? 'is-invalid' : ''}`
                }
                placeholder='Your last name'
                {...register('lastName')}
              />
              <div className="invalid-feedback">
                {errors.lastName?.message}
              </div>
            </div>
            <div class="col-12 gy-6">
              <div class="row g-3 justify-content-start">
                {/* <div class="col-auto"><button class="btn btn-phoenix-primary px-5">Cancel</button></div> */}
                <div class="col-auto">
                  <button class="btn btn-primary px-5 px-sm-8 d-flex">
                    {submited ?
                    <>
                      <span className="spinner-border custom-spinner-border-sm" role="status" aria-hidden="true"></span>
                      &#160;Updating...
                    </>
                    :
                    <>Update New Password</>
                    }
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  };

  return (
    <div className="mb-9 setting-page">
      <div className="row g-3 mb-5">
        <div className="col-auto">
          <h2 className="mb-2">Settings</h2>
          <h5 className="text-700 fw-semi-bold">Manage Your Account Informations</h5>
        </div>
      </div>
      {/* <div className='row'>
        <div className='col-lg-6 col-md-12 col-sm-12 d-flex align-items-center '>
          <div className='avatar avatar-2xl avatar-bordered'>
            <img className='rounded-circle' src='https://api.senisha.com/images/1716257061998045cfe65e09f0451b5c03fc6ce7cd34f.jpg' alt='Profile' />
          </div>
          <div className='ms-2'>
            <p className='m-0'>Profile</p>
            <p className='m-0'>Please update your profile settings here.</p>
          </div>
        </div>
        <div className='col-lg-6 col-md-84 col-sm-12 d-flex align-items-center justify-content-end'>
          <button className='btn btn-phoenix-secondary rounded-pill ms-1 mb-1' type='button'>
            Upgrade <span className='fas fa-align-right ms-2' data-fa-transform='shrink-3'></span>
          </button>
        </div>
      </div> */}
      <div className='col-sm-12 col-lg-6'>{ settingForm() }</div>
    </div>
  )
}

export default Setting