import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import cookie from 'react-cookies';
import axios from 'axios';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

const Login = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const from = location.state?.from?.pathname || '/';

    const schema = yup.object({
        email   : yup.string().email().required("Email address is required"),
        password: yup.string().required('Password is mendatory'),
    });

    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const notify = (message) => toast.error(message,
        {
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        }
    );
    const onSubmit = async (data) => {
        const requestBody = { ...data}

        try {
            const response = await axios.post('auth/login', requestBody);
            if (response.data.result === true) {
                cookie.save('authToken', 'Bearer ' + response.data.jwtToken, { path: '/' });
                cookie.save('roles', response.data.role, { path: '/' });

                axios.defaults.headers.common['Authorization'] = cookie.load('authToken');

                navigate(from, { replace: true });
            } else {
                notify(response.data.message) // Display error message returned from the server
            }
        } catch (err) {
            console.error("Error:", err);
            if (err.response) {
                notify(err.response.data.message)
            } else {
                notify('An error occurred. Please try again later.')
            }
        }
    }

    return (
        <>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <main class="main" id="top">
                <div class="container-fluid px-0" data-layout="container">
                    <div class="container">
                        <div class="row flex-center min-vh-100">
                            <div class="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-5">
                                <div className='mb-4'>
                                    {/* <img src='/logo.png' /> */}
                                    <h3>Sign in to your account</h3>
                                    <p>Connect Professionally, Impress Instantly</p>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div class="mb-3 text-start">
                                        <label class="mb-1" for="email">Email address <span className='text-danger'>*</span></label>
                                        <div class="form-icon-container">
                                            <input
                                                type="text"
                                                placeholder="Enter your email"
                                                {...register('email')}
                                                className={
                                                    `form-control form-icon-input auth-input `
                                                    + `${errors.hasOwnProperty('email') ? 'is-invalid' : ''}`
                                                }
                                            />
                                            <span class="fas fa-user text-900 fs--1 form-icon input-icon"></span>
                                            <div className="invalid-feedback">
                                                {errors.email?.message}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3 text-start">
                                        <label class="mb-1" for="password">Password <span className='text-danger'>*</span></label>
                                        <div class="form-icon-container">
                                            <input
                                                type="password"
                                                placeholder="Enter your password"
                                                {...register('password')}
                                                className={
                                                    `form-control form-icon-input auth-input `
                                                    + `${errors.hasOwnProperty('password') ? 'is-invalid' : ''}`
                                                }
                                            />
                                            <span class="fas fa-key text-900 fs--1 form-icon input-icon"></span>
                                            <div className="invalid-feedback">
                                                {errors.password?.message}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex-between-center mb-5">
                                        <div class="col-auto">
                                            <div class="form-check mb-0 rem-section">
                                                <input class="form-check rem-input" id="basic-checkbox" type="checkbox" />
                                                <label class="form-check-label mb-0" for="basic-checkbox">Remember me</label>
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <Link class="fs--1 fw-semi-bold" to="/forgot-password">Forgot Password?</Link>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary w-100 mb-3">Sign In</button>
                                </form>
                                <p>Don’t have an account? <a href='/signup'>Sign up</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

function mapStateToProps(state) {
    const auth = state.authenticate.auth;

    return { auth };
}

export default connect(mapStateToProps)(Login);
