import axios from "axios";
import { getContactsReducer, getContactReducer, addResultReducer } from "../../slice/user/contact";

export const getContacts = (page, query, size) => async (dispatch) => {
    try {
        const response = await axios.get(
            `/contact?${page ? 'page=' + page : ''}` +
            `${query ? '&query=' + query : '' + '&size=10'}`
        );
        // if (response.data.result === 'success') {
        dispatch(getContactsReducer(response.data));
        // }
    } catch (err) { throw new Error(err); }
};

export const addContact = (data) => async (dispatch) => {
    try {
        const response = await axios.post('contact', data);

        if (response.status === 200) dispatch(addResultReducer(response.data));
    } catch (err) { throw new Error(err); }
};

export const updateContact = (id, data) => async (dispatch) => {
    try {
        const response = await axios.put(`contact/${id}`, data);

        if (response.data.success === true) dispatch(getContact());
    } catch (err) { throw new Error(err); }
};

export const getContact = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`contact/${id}`);

        // if (response.data.result === 'success') {
        dispatch(getContactReducer(response.data)); //Real fuction in fure...
        // }
    } catch (err) { throw new Error(err); }
};

export const deleteContact = (id) => async (dispatch) => {
    try {
        const response = await axios.delete(`/contact/${id}`);

        if (response.status === 200) dispatch(getContacts());
    } catch (err) { throw new Error(err); }
};