import axios from "axios";
import { getCardsReducer, getCardReducer, getPublicCardReducer, getResultReducer, updateResultReducer } from "../../slice/user/card";

export const getCards = () => async (dispatch) => {
    try {
        const response = await axios.get('card?size=5&page=1');

        if (response.status === 200) {
            dispatch(getCardsReducer(response.data));
            dispatch(getResultReducer(response.status));
        }
    } catch (err) { throw new Error(err); }
};

// export const addBrand = (data) => async (dispatch) => {
//     try {
//         const response = await axios.post('admin/brands', data);

//         if (response.data.result === 'success') dispatch(getBrands());
//     } catch (err) { throw new Error(err); }
// };

export const updateCard = (id, data) => async (dispatch) => {
    try {
        const response = await axios.put(`card/${id}`, data);

        if (response.status === 200) {
            dispatch(getCard());
            dispatch(updateResultReducer(response.status));
        }
    } catch (err) { throw new Error(err); }
};

export const getCard = (id) => async (dispatch) => {
    try {
        // const response = await axios.get(`card/${id}`); // Real feut.. function
        const response = await axios.get(`card?size=1&page=1`); // Temp fuction

        // if (response.data.result === 'success') {
            dispatch(getCardReducer(response.data)); //Real fuction in fure...
        // }
    } catch (err) { throw new Error(err); }
};

export const getPublicCard = (slug) => async (dispatch) => {
    try {
        const response = await axios.get(`/card/cid/${slug}`);

        // if (response.data.result === 'success') {
            dispatch(getPublicCardReducer(response.data)); //Real fuction in fure...
        // }
    } catch (err) { throw new Error(err); }
};

export const deleteSocialLink = (id, key) => async (dispatch) => {
    try {
        const response = await axios.delete(`/card/${id}/links/${key}`);
        dispatch(getCards())

    } catch (err) { throw new Error(err); }
};