import axios from 'axios';
import * as yup from "yup";
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useLocation } from 'react-router';

const Register = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [submited, setSubmited] = useState(false)

    const from = location.state?.from?.pathname || '/login';

    const schema = yup.object({
        firstName      : yup.string().required("First name is required"),
        lastName       : yup.string().required("Last name is required"),
        email          : yup.string().email().required("Email address is required"),
        acceptTerms    : yup.boolean().oneOf([true], "Agree to our Terms and Conditions to continue.").required(),
        password       : yup.string().required('Password is mendatory').min(6, 'Password must be at 6 char long'),
        confirmPassword: yup.string().required('Password is mendatory').oneOf([yup.ref('password')], 'Passwords does not match'),
    });

    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        const requestBody = { ...data}
        setSubmited(true)

        try {
            const response = await axios.post('auth/register', requestBody);
            if (response.status === 200) {
                setSubmited(false)
                notifySuccess(response.data.message);
                setTimeout(() => {
                    navigate(from, { replace: true });
                }, 1000);
            } else {
                notify(response.data.message); // Display error message returned from the server
            }
        } catch (err) {
            console.error("Error:", err);
            if (err.response) {
                notify(err.response.data.message)
            } else {
                notify('An error occurred. Please try again later.')
            }
        }
    }

    const notify = (message) => toast.error(message,
        {
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        }
    );

    const notifySuccess = (message) => toast.success(message,
        {
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        }
    );

    return (
        <>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <main class="main" id="top">
                <div class="container-fluid px-0" data-layout="container">
                    <div class="container">
                        <div class="row flex-center min-vh-100">
                            <div class="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-5">
                                <div className='mb-4'>
                                    {/* <img src='/logo.png' /> */}
                                    <h3>Create Your Account</h3>
                                    <p>Sign up to unlock your professional profile</p>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)} className="theme-form">
                                    <div className='row'>
                                        <div class="mb-3 text-start col-6">
                                            <label class="mb-1" for="email">First name <span className='text-danger'>*</span></label>
                                            <div class="form-icon-container">
                                                <input
                                                    placeholder="Enter your first name"
                                                    {...register('firstName')}
                                                    className={
                                                        `form-control form-icon-input auth-input `
                                                        + `${errors.hasOwnProperty('firstName') ? 'is-invalid' : ''}`
                                                    }
                                                />
                                                <span class="fas fa-user text-900 fs--1 form-icon input-icon"></span>
                                                <div className="invalid-feedback">
                                                    {errors.firstName?.message}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-3 text-start col-6">
                                            <label class="mb-1" for="email">Last name <span className='text-danger'>*</span></label>
                                            <div class="form-icon-container">
                                                <input
                                                    placeholder="Enter your last name"
                                                    {...register('lastName')}
                                                    className={
                                                        `form-control form-icon-input auth-input `
                                                        + `${errors.hasOwnProperty('lastName') ? 'is-invalid' : ''}`
                                                    }
                                                />
                                                <span class="fas fa-user text-900 fs--1 form-icon input-icon"></span>
                                                <div className="invalid-feedback">
                                                    {errors.lastName?.message}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3 text-start">
                                        <label class="mb-1" for="email">Email address <span className='text-danger'>*</span></label>
                                        <div class="form-icon-container">
                                            <input
                                                placeholder="Enter your working email"
                                                required=""
                                                {...register('email')}
                                                className={
                                                    `form-control form-icon-input auth-input `
                                                    + `${errors.hasOwnProperty('email') ? 'is-invalid' : ''}`
                                                }
                                            />
                                            <span class="fas fa-user text-900 fs--1 form-icon input-icon"></span>
                                            <div className="invalid-feedback">
                                                {errors.email?.message}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3 text-start">
                                        <label class="mb-1" for="password">Password <span className='text-danger'>*</span></label>
                                        <div class="form-icon-container">
                                            <input
                                                type="password"
                                                placeholder="Password (min 6 characters)"
                                                {...register('password')}
                                                className={
                                                    `form-control form-icon-input auth-input `
                                                    + `${errors.hasOwnProperty('password') ? 'is-invalid' : ''}`
                                            }
                                            />
                                            <span class="fas fa-key text-900 fs--1 form-icon input-icon"></span>
                                            <div className="invalid-feedback">
                                                {errors.password?.message}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3 text-start">
                                        <label class="mb-1" for="password">Confirm Password <span className='text-danger'>*</span></label>
                                        <div class="form-icon-container">
                                            <input
                                                type="password"
                                                placeholder="Confirm your password"
                                                {...register('confirmPassword')}
                                                className={
                                                    `form-control form-icon-input auth-input `
                                                    + `${errors.hasOwnProperty('confirmPassword') ? 'is-invalid' : ''}`
                                                }
                                            />
                                            <span class="fas fa-key text-900 fs--1 form-icon input-icon"></span>
                                            <div className="invalid-feedback">
                                                {errors.confirmPassword?.message}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex-between-center mb-5">
                                        <div class="col-auto">
                                            <div class="form-check mb-0 rem-section">
                                                <input
                                                    id="basic-checkbox"
                                                    type="checkbox"
                                                    {...register('acceptTerms')}
                                                    className={
                                                        `form-check rem-input `
                                                        + `${errors.hasOwnProperty('acceptTerms') ? 'is-invalid' : ''}`
                                                    }
                                                />
                                                <label class="form-check-label mb-0" for="basic-checkbox">I agree to the Terms and Conditions</label>
                                                <div className="invalid-feedback d-contents">
                                                    {errors.acceptTerms?.message}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <button className="btn btn-primary w-100 mb-3">Sign Up</button> */}
                                    <button class="btn btn-primary w-100 mb-3 mt-3 d-flex text-ceneter f-pass-btn">
                                        {submited ?
                                            <>
                                                <span className="spinner-border custom-spinner-border-sm" role="status" aria-hidden="true"></span>
                                                &#160;Sign Up
                                            </>
                                            :
                                            <>Sign Up</>
                                        }
                                    </button>
                                </form>
                                <p className='fs-sm'>
                                    By proceeding, you accept Senisha's <a href=''> Terms of Use</a>. Please review our <a href=''>Privacy Policy</a>.
                                </p>
                                <p className='fs-sm'>Already have an account? <a href='/login'>Sign in</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Register;