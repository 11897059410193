export const API_URL               = process.env.REACT_APP_API_URL;
export const APP_URL               = process.env.REACT_APP_APP_URL;
export const CURRENCY              = '$';
export const QUICK_CONTACT_NUMBER  = '+41798645352';
export const QUICK_CONTACT_MESSAGE = 'Hello! Emmental Grossist';
export const VERSION               = '1 (1.0.0)';

export const ORDER_STATUS = {
    PENDING   : 'pending',
    PROCESSING: 'processing',
    CONFIRMED : 'confirmed',
    CANCELED  : 'canceled',
    DELIVERED : 'delivered',
    PICKED    : 'picked',
};