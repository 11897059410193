import React from 'react';

function GenerateContact({ data }) {
  const saveContact = () => {
    const vCardData = generateVCard(data);
    const vCardBlob = new Blob([vCardData], { type: 'text/vcard' });
    const vCardURL = URL.createObjectURL(vCardBlob);
    
    // Create an anchor element and trigger a click to open the vCard file
    const a = document.createElement('a');
    a.href = vCardURL;
    a.download = `${data.name}.vcf`;
    a.click();
    
    // Clean up by revoking the Object URL
    URL.revokeObjectURL(vCardURL);
  };

  const generateVCard = (data) => {
    return `BEGIN:VCARD
VERSION:3.0
FN:${data.name}
ORG:${data.company}
ADR:${data.address}
TEL;TYPE=CELL:${data.phone}
EMAIL:${data.email}
URL:${data.cardUrl}
END:VCARD`;
  };

  return (
    <button onClick={saveContact} className='btn btn-primary'>
        Save contact
    </button>
  );
}

export default GenerateContact;
