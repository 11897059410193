import axios from "axios";
import { fileUploadReducer } from "../../slice/user/file";

export const fileUpload = (data) => async (dispatch) => {
    try {
        const formData = new FormData();
        formData.append('cardId', data.cardId);
        formData.append('image', data.image);
        formData.append('name', data.name);
        formData.append('title', data.title);

        const response = await axios.put(`file/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (response.status === 200) {
            dispatch(fileUploadReducer(response));
        }
    } catch (err) { 
        throw new Error(err); 
    }
};

export const serviceFileUpload = (data) => async (dispatch) => {
    try {
        const formData = new FormData();
        formData.append('id', data.id);
        formData.append('image', data.image);
        formData.append('name', data.name);
        formData.append('title', data.title);

        const response = await axios.put(`file/serviceimg`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (response.status === 200) {
            dispatch(fileUploadReducer(response));
        }
    } catch (err) { 
        throw new Error(err); 
    }
};
