import axios from "axios";
import { getServicesReducer, getServiceReducer, addResultReducer } from "../../slice/user/service";

export const getServices = (page, query, size) => async (dispatch) => {
    try {
        const response = await axios.get(
            `/service?${page ? 'page=' + page : ''}` +
            `${query ? '&query=' + query : '' + '&size=8'}`
        );
        // if (response.data.result === 'success') {
        dispatch(getServicesReducer(response.data));
        // }
    } catch (err) { throw new Error(err); }
};

export const addService = (data) => async (dispatch) => {
    try {
        const response = await axios.post('service', data);

        if (response.status === 200) dispatch(addResultReducer(response.data));
    } catch (err) { throw new Error(err); }
};

export const updateService = (id, data) => async (dispatch) => {
    try {
        const response = await axios.put(`service/${id}`, data);

        if (response.data.success === true) dispatch(getService());
    } catch (err) { throw new Error(err); }
};

export const getService = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`service/${id}`);

        // if (response.data.result === 'success') {
        dispatch(getServiceReducer(response.data)); //Real fuction in fure...
        // }
    } catch (err) { throw new Error(err); }
};

export const deleteService = (id) => async (dispatch) => {
    try {
        const response = await axios.delete(`/service/${id}`);

        if (response.status === 200) dispatch(getServices());
    } catch (err) { throw new Error(err); }
};