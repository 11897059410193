// NotFoundPage.jsx

import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <main class="main" id="top">
            <div class="container-fluid px-0" data-layout="container">
                <div class="container">
                    <div class="row flex-center min-vh-100">
                        <div class="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-5">
                            <div className="not-found-container">
                                <div className="not-found-content">
                                    <h1>404</h1>
                                    <p>Page Not Found</p>
                                    <Link to="/" className="back-to-home-link">Go back to home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default NotFound;
