import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import axios from 'axios';

const MailVerification = (props) => {
    const location = useLocation();

    const getTokenFromUrl = () => {
        const params = new URLSearchParams(location.search);
        return params.get('token');
    };

    const token = getTokenFromUrl();

    const [verificationMessage, setVerificationMessage] = useState('');
    const [errorMessage, setErrorMessage]               = useState('');

    useEffect(() => {
        const verifyEmail = async () => {
            const requestBody = { token };
            try {
                const response = await axios.post('auth/verify-email', requestBody);
                if (response.status === 200) {
                    setVerificationMessage(response.data.message);
                    setErrorMessage('');
                } else {
                    setVerificationMessage('');
                    setErrorMessage('An error occurred. Please try again later.');
                }
            } catch (err) {
                console.error("Error:", err);
                if (err.response) {
                    setVerificationMessage('');
                    setErrorMessage(err.response.data.message || 'An error occurred.');
                } else {
                    setVerificationMessage('');
                    setErrorMessage('An error occurred. Please try again later.');
                }
            }
        };

        verifyEmail();
    }, [token]);

    return (
        <main class="main" id="top">
            <div class="container-fluid px-0" data-layout="container">
                <div class="container">
                    <div class="row flex-center min-vh-100">
                        <div class="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-5">
                            {errorMessage &&
                                <div className='mb-4 text-center v-failed'>
                                    <i class="far fa-circle-xmark e-verify-icon text-danger"></i>
                                    <h3>Verification Link Expired</h3>
                                    <p className='m-0 p-0'>Refresh Your Verification Status by Requesting a New Link.</p>
                                    <a className='mt-2' href='/forgot-password'><i class="fal fa-arrow-rotate-right"></i> Try again</a>
                                </div>
                            }
                            {verificationMessage &&
                                <div className='mb-4 text-center v-success'>
                                    <i class="far fa-circle-check e-verify-icon text-success"></i>
                                    <h3>Email Verification Success</h3>
                                    <p className='m-0 p-0'>Your email is verified and your account is ready to use.</p>
                                    <a className='mt-2' href='/login'><i class="far fa-circle-arrow-left"></i> Go to Login</a>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

function mapStateToProps(state) {
    const auth = state.authenticate.auth;

    return { auth };
}

export default connect(mapStateToProps)(MailVerification);
