import { createSlice } from '@reduxjs/toolkit'

export const fileSlice = createSlice({
    name: 'file',
    initialState: {
        result : '',
    },
    reducers: {
        fileUploadReducer: (state, action) => {
            state.result = action.payload.data.success;
        },
    },
});

export const { fileUploadReducer } = fileSlice.actions;
export default fileSlice.reducer;