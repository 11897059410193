import { configureStore } from '@reduxjs/toolkit';
import autheticateReducer from './slice/auth/auth';
import cardReducer from './slice/user/card';
import contactReducer from './slice/user/contact';
import productReducer from './slice/user/product';
import serviceReducer from './slice/user/service';
import fileReducer from './slice/user/file';

export default configureStore({
    reducer: {
        authenticate: autheticateReducer,
        cards       : cardReducer,
        contacts    : contactReducer,
        products    : productReducer,
        services    : serviceReducer,
        files       : fileReducer,
    },
});